

.bottom-border {
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: var(--bs-gray-200);
}

.top-border {
  border-top-style: solid;
  border-top-width: thin;
  border-top-color: var(--bs-gray-200);
}

.chat-bubble {
  background-color: var(--bs-gray-200);
  border-radius: 1rem;

}